import React from "react"

function U({ className, ...props }) {
  return (
    <svg
      width={72}
      height={72}
      fill="none"
      viewBox="0 0 176 197"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.002 29.81c-.1-4.7 3.6-8.8 8.8-9.5l129.7-18.5c.8-.1 1.6.4 1.7 1.2.1.8-.4 1.5-1.2 1.6l-129.7 18.6c-3.6.5-6.3 3.4-6.2 6.6l1.2 41.7c0 .8-.6 1.4-1.4 1.4-.8 0-1.5-.6-1.5-1.4l-1.4-41.7Z"
        fill="#527693"
      />
      <path
        d="m24.902 48.11 124.5-13.4c.8-.1 1.6.5 1.6 1.2.1.8-.5 1.5-1.3 1.5-129 13.9-124.4 13.4-124.6 13.4-.7 0-1.4-.5-1.5-1.2-.1-.8.5-1.5 1.3-1.5ZM81.602 24.21c-.1-.8.5-1.5 1.3-1.6l51.4-6.6c.8-.1 1.6.4 1.7 1.2.1.8-.5 1.5-1.3 1.6l-51.4 6.6c-.8.1-1.6-.5-1.7-1.2ZM115.302 25.61c.8-.1 1.5.5 1.6 1.3.1.8-.5 1.4-1.4 1.5-9.7.8-11.1.9-11.2.9-.8 0-1.4-.6-1.5-1.3-.1-.8.5-1.4 1.4-1.5l11.1-.9ZM21.102 39.31c-1.8 2.8-5.3 3.1-7.6.6-3.3-3.6-1.9-10.3 2.8-10.7 4.7-.4 7.3 6 4.8 10.1ZM26.102 33.81c-.6-5.8 5.1-8.6 8.6-4.9 3.3 3.6 1.9 10.3-2.8 10.7-.2-.1-5.4-2.1-5.8-5.8ZM40.102 31.41c-.3-3.5 1.7-6.4 4.6-6.6 2.6-.2 5.4 2 5.7 5.7.3 3.5-1.7 6.4-4.6 6.6-2.7.3-5.4-2-5.7-5.7ZM107.802 140.11c-.2-.3-.3-.7-.3-1.1 1.4-8.1 3.3-18.7 4.6-26.3.2-1.1 1.6-1.5 2.4-.8 7.1 5.8 13.9 11.4 21 17.2.2.2.4.5.5.7 1.5 6.2 1.6 12.7 1.9 19.2.4-.1.8-.2 1.1-.3.7-.2 1.5.1 1.8.8 2.5 5.3 4.6 10.8 7.1 16.1.3.7 0 1.6-.8 1.9-6.4 2.5-14.4 5.5-20.4 7.1h-.4c-.6 0-1.2-.3-1.4-.9-2-4.9-4-11.9-6-16.8-.5-.6-.5-1.3 0-1.8.3-.4.7-.5 1-.6-1.7-2.1-3.5-4.1-5.2-6-2.2-2.8-4.6-5.4-6.9-8.4Zm15.2 13.3c5-1.5 7-2.1 11.9-3.6-.3-6.3-.3-12.9-1.7-19-5.6-4.5-10.3-8.5-17.2-14.1l4.9 11.3c.3.7-.1 1.5-.8 1.8-.8.3-1.6-.1-1.9-.8l-4.2-9.6c-1.2 7.1-2.4 13.3-3.4 19.5 4 5 8.1 9.3 12.4 14.5Z"
        fill="#527693"
      />
      <path
        d="M119.602 130.51c2.2-1.3 5.3-.7 6.7 1.3 1.5 2.1.7 5.1-1.5 6.4-2.2 1.3-5.3.6-6.7-1.4-1.4-2-.7-5 1.5-6.3ZM68.502 76.41c8.5-5.1 19.7-4.3 27.4 2.1l-7.2-15c-1.5-.2-2.7-1.3-2.9-2.8-.2-1.8 1.1-3.4 3-3.6 3.3-.4 5.2 3.4 2.8 5.6l19.2 40c1.7 0 3.2 1.2 3.4 2.8.2 1.8-1.1 3.4-3 3.6-3.1.4-5.1-3-3.1-5.3l-4.6-9.6c.1 9.9-7.1 18.8-17.8 21-7.9 1.6-15.7-.8-21-5.7 12.4-7.4 13.9-23.7 3.8-33.1Z"
        fill="#527693"
      />
      <path
        d="M28.002 116.31c-1.8.5-3.7-.5-4.2-2.3-.5-1.6.5-3.3 2.1-3.9l5.1-44.4c-2.6-1.7-1.6-5.5 1.5-5.9 1.9-.2 3.6 1 3.9 2.8.2 1.6-.9 3.1-2.5 3.5l-1.8 15.8c7-13 25.4-15.8 36.4-5.5-12.4 7.4-14.1 23.6-3.9 33.2-12.9 7.7-30.3 1.5-34.2-12.7l-1.6 13.6c2.7 1.3 2.2 5.1-.8 5.8ZM173.202 88.81c1.1 25.9 2.1 51.9 2.6 77.9.1 6.1.2 12.4-2.4 18-1.5 3.4-4.3 6.6-7.7 8.5-1.9 1.8-4.5 2.9-7.4 2.9-1.1 0 5.9.6-120-11.6-.8-.1-1.4-.8-1.3-1.5.1-.8.8-1.3 1.6-1.2l118.9 11.6c4.4.4 8.3-2.7 8.2-6.7 0-.1-4.9-178-4.9-177.5-.1-3.9-4.1-6.9-8.5-6.3l-5.4.8c-.8.1-1.6-.4-1.7-1.2-.1-.8.4-1.5 1.2-1.6l5.4-.8c2.3-.3 4.6 0 6.6 1.1.1 0 .2.1.2.1.6.3 5.1 1.5 8.4 5.7 3.4 4.3 2.6 8.2 2.9 13.1 1.1 17.1.1-7.9 3.2 66.2"
        fill="#527693"
      />
    </svg>
  )
}

export default U
