import React from "react"

function Quote({ ...props }) {
  return (
    <svg
      width={39}
      height={39}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.792 2.976v6.508c0 .882-.716 1.597-1.598 1.597-3.15 0-4.862 3.23-5.1 9.605h5.1c.882 0 1.598.715 1.598 1.598v13.74c0 .883-.716 1.598-1.598 1.598H1.598A1.598 1.598 0 0 1 0 36.025V22.284c0-3.056.308-5.86.914-8.336.622-2.539 1.577-4.758 2.838-6.597 1.296-1.89 2.919-3.373 4.821-4.406 1.916-1.04 4.144-1.567 6.621-1.567.882 0 1.598.715 1.598 1.598Zm20.61 8.105c.882 0 1.598-.715 1.598-1.597V2.976c0-.883-.716-1.598-1.598-1.598-2.477 0-4.705.527-6.62 1.567C28.88 3.978 27.256 5.46 25.96 7.35c-1.26 1.84-2.215 4.059-2.837 6.598-.606 2.476-.914 5.28-.914 8.335v13.74c0 .883.716 1.598 1.598 1.598h13.596c.882 0 1.597-.715 1.597-1.597V22.284c0-.882-.715-1.598-1.597-1.598h-5.027c.234-6.375 1.922-9.605 5.027-9.605Z"
        fill="#527693"
      />
    </svg>
  )
}

export default Quote
