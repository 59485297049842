import React from "react"

function DrawnArrow({ ...props }) {
  return (
    <svg
      height={24}
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96.3 96.3"
      style={{
        enableBackground: "new 0 0 96.3 96.3",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path d="M44.516 50.7c-2.2 6.6-1 13.3-1.4 20 0 .3.7.8 1.1.9 1 .3 1.8-.1 2.4-.6 2-1.9 2.9-4.1 3.1-6.4.6-5.5 1.4-11.1.8-16.6.1-.7.2-1.4.2-2.1.2-2.8.3-5.6.2-8.3 2 2.1 4 3.9 6.4 5.5 1 .7 2.3.8 3.5-.6 1.1-1.2 1.3-2.6.6-3.7-1.3-1.8-2.6-3.6-4.2-5.2-2.6-2.5-5.4-4.7-8.1-7.1-.5-1.1-1.8-1.5-2.9-1l-1.1.6c-5.9 3-9.5 8.4-14.3 12.5-.2.2-.1 1 .2 1.4.5.9 1.3 1.2 2.1 1.2 2.7.2 4.9-.8 6.6-2.2 1.4-1.2 2.9-2.3 4.3-3.4-.2 1.3-.3 2.6-.3 3.9.1 3.8.5 7.5.8 11.2z" />
      <path d="M92.316 52.9c-1.7 6.3-4.6 12.6-8.9 18.3-.5.7-1.1 1.4-1.7 2.1l-.9 1-.4.5-.5.5-1.9 1.9-.5.5-.6.4-1.1.9c-1.3 1.2-2.9 2.2-4.4 3.3-.7.5-1.6.9-2.4 1.4-.8.4-1.6 1-2.4 1.3-3.9 2-8.2 3.2-12.6 3.9-1.1.1-2.2.4-3.3.4l-1.6.1H47.616l-3.4-.2-.9-.1c-.3 0-.5-.1-.8-.1l-1.6-.3-1.6-.3c-.5-.1-1.1-.3-1.6-.4l-1.6-.4c-.5-.1-1.1-.3-1.6-.5l-3-1.2c-1-.5-2-1-2.9-1.4-3.8-2.1-7.3-4.7-10.4-7.8-3-3.1-5.5-6.8-7.4-10.6l-.7-1.5-.4-.7-.3-.8-1.1-3.1-.8-3.1c-.1-.3-.1-.5-.2-.8l-.1-.8-.2-1.6-.3-1.6-.1-1.6-.1-1.6v-1.5l.2-3.5c.1-1.1.3-2.1.4-3.1.7-4.2 2.1-8.4 4.1-12.3 2.2-3.7 4.9-7.1 8-10.1 9.9-9.4 24.6-13.2 37.8-9.8l2.5.6 2.4.9 1.2.4c.4.2.8.4 1.2.5l2.3 1.1c2.9 1.7 5.8 3.5 8.3 5.8 1.3 1.1 2.4 2.4 3.6 3.6.6.6 1.1 1.3 1.6 2s1.1 1.3 1.5 2c.9 1.4 1.9 2.8 2.6 4.4.4.8.8 1.5 1.2 2.3l.9 2.4c2.9 7.2 3.3 14 5.2 13.4.8-.3 1.4-1.9 1.8-4.7 0-1.4 0-3-.1-4.9-.2-.9-.3-1.9-.5-2.9-.1-.5-.2-1-.4-1.6-.2-.5-.4-1.1-.5-1.6-1.2-3.9-3.2-7.6-5.4-11.1-1.2-1.7-2.3-3.4-3.8-4.9l-1-1.2c-.3-.4-.7-.8-1.1-1.1l-2.3-2.1c-3.2-2.7-6.6-5.1-10.4-6.9-1.8-1-3.8-1.6-5.8-2.4-1-.4-2-.5-3-.8s-2-.6-3.1-.7l-2.6-.4-1.3-.2-.9-.4-2.3-.1h-1.4l-1.2.1c-1.7.1-3.4.1-5 .4-1.7.3-3.3.5-4.9 1-6.5 1.6-12.6 4.6-17.9 8.8-5.2 4.2-9.6 9.4-12.6 15.3l-1.1 2.2c-.3.8-.7 1.5-1 2.3l-.7 2.4-.4 1.2-.3 1.2-.6 2.4-.3 1.2-.2 1.3c-.2 1.7-.5 3.4-.5 4.9-.7 5.8-.2 12 1.5 17.8s4.5 11.3 8.4 16.1c3.8 4.8 8.6 8.8 14 11.8l4.2 2c1.4.6 2.9 1.1 4.3 1.6l4.5 1.2c1.5.3 3.2.5 4.7.8l.6.1h.5l1.1.1 2.1.1H47.916l1.2-.1 2.3-.1 1.2-.1c.4 0 .8-.1 1.2-.2l2.3-.4c1.5-.2 3-.6 4.5-1 6-1.6 11.6-4.5 16.5-8.2 4.9-3.8 9.1-8.4 12.2-13.7.4-.7.8-1.3 1.2-2l1-2.1c.3-.7.7-1.4 1-2.1l.8-2.2c.3-.7.5-1.4.8-2.2l.6-2.2.6-2.2.4-2.4c.5-2.4-.2-5.5-.6-6.5-1.1-2-1.9.5-2.8 4.3z" />
    </svg>
  )
}

export default DrawnArrow
