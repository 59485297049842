import React from "react"

function Arrow({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 122.88 122.88"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M61.439 0v.016c-16.976 0-32.335 6.874-43.443 17.981S.016 44.464.016 61.438H0v.002h.016c0 16.978 6.874 32.336 17.981 43.444 11.107 11.106 26.467 17.98 43.441 17.98v.016h.002v-.016c16.977 0 32.336-6.874 43.443-17.98 11.107-11.108 17.981-26.467 17.981-43.441h.016v-.003h-.016c0-16.976-6.874-32.335-17.981-43.442S78.416.016 61.442.016V0h-.003zM51.181 42.479a4.958 4.958 0 0 1 7.107-6.912l21.838 22.519-3.554 3.456 3.569-3.458a4.97 4.97 0 0 1-.283 7.188l-21.57 22.057a4.957 4.957 0 0 1-7.107-6.912l18.473-18.889-18.473-19.049z" />
    </svg>
  )
}

export default Arrow
