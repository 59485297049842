import React from "react"

function W({ className, ...props }) {
  return (
    <svg
      width={72}
      height={72}
      fill="none"
      viewBox="0 0 187 194"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M88.338 131.411c-9.7-4.8-16.8-8.7-26.4-13.4-2.2-1.1-2.7-3.9-1.1-5.7l22.3-25.3c1.3-1.5 3.7-1.7 5.2-.3 1.5 1.3 1.7 3.7.3 5.2l-19.1 21.7c7.9 4 13.9 7.2 22.1 11.2 1.8.9 2.6 3.1 1.7 4.9-1 1.9-3.2 2.6-5 1.7ZM117.338 132.411c-.8-1.9 0-4 1.9-4.8 8.4-3.7 14.6-6.7 22.5-10.3l-18.2-22.5c-1.3-1.6-1-3.9.5-5.2 1.6-1.3 3.9-1 5.2.5l21.2 26.2c1.5 1.9.8 4.7-1.4 5.7-9.8 4.3-16.8 7.8-26.9 12.3-1.8.8-3.9 0-4.8-1.9ZM94.038 149.111c-1.9-.7-2.9-2.8-2.1-4.7 8.3-22.1 16-44.7 22.8-67.2.6-1.9 2.6-3 4.6-2.5 1.9.6 3 2.6 2.5 4.6-6.8 22.7-14.6 45.4-23 67.7-.8 1.8-2.9 2.8-4.8 2.1ZM186.838 29.711c.1-4.6-3.9-8.6-9.3-9.4l-137.9-18.3c-.9-.1-1.7.4-1.8 1.2-.1.8.5 1.5 1.3 1.6l137.9 18.3c3.9.5 6.7 3.3 6.6 6.6l-1.2 41.2c0 .8.7 1.4 1.5 1.4.9 0 1.6-.6 1.6-1.3l1.3-41.3Z"
        fill="#527693"
      />
      <path
        d="m160.338 47.711-132.3-13.2c-.9-.1-1.7.5-1.8 1.2-.1.8.5 1.4 1.4 1.5 137.1 13.7 132.3 13.2 132.5 13.2.8 0 1.5-.5 1.6-1.2.1-.7-.5-1.4-1.4-1.5ZM100.138 24.111c.1-.8-.5-1.5-1.4-1.6l-54.6-6.5c-.9-.1-1.7.4-1.8 1.2-.1.8.5 1.5 1.4 1.6l54.6 6.5c.9.1 1.7-.4 1.8-1.2ZM64.338 25.611c-.9-.1-1.6.5-1.7 1.3-.1.8.6 1.4 1.4 1.5 10.3.8 11.8.9 11.9.9.8 0 1.5-.5 1.6-1.3.1-.8-.6-1.4-1.4-1.5l-11.8-.9ZM164.438 39.111c1.9 2.8 5.7 3.1 8.1.6 3.5-3.5 2-10.2-3-10.5-5-.4-7.8 5.9-5.1 9.9ZM159.138 33.711c.6-5.8-5.5-8.5-9.1-4.9-3.5 3.5-2 10.2 3 10.5.1 0 5.7-2 6.1-5.6ZM144.338 31.311c.3-3.4-1.8-6.3-4.9-6.5-2.8-.2-5.7 2-6.1 5.7-.3 3.4 1.8 6.3 4.9 6.5 2.8.2 5.7-2 6.1-5.7Z"
        fill="#527693"
      />
      <path
        d="M2.838 88.01c-1.2 25.601-2.2 51.301-2.8 76.901-.1 6-.2 12.2 2.5 17.8 1.6 3.4 4.6 6.5 8.2 8.4 2.1 1.8 4.8 2.8 7.9 2.8 1.2 0-6.3.6 127.5-11.5.9-.1 1.5-.8 1.4-1.5-.1-.8-.9-1.3-1.7-1.2l-126.3 11.4c-4.6.4-8.8-2.7-8.7-6.6 0-.1 5.3-175.9 5.2-175.4.1-3.9 4.3-6.9 9.1-6.2l5.7.8c.9.1 1.7-.4 1.8-1.2.1-.8-.5-1.5-1.3-1.6l-5.7-.8c-2.5-.3-4.9 0-7.1 1-.1 0-.2.1-.2.1-.7.3-5.4 1.5-8.9 5.6-3.6 4.2-2.8 8.1-3.1 12.9-1.1 16.9-.1-7.8-3.4 65.4"
        fill="#527693"
      />
    </svg>
  )
}

export default W
